.categories {
    padding: 5px;
    border-bottom: 10px solid #eaeaf2;
}

.cat-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 5px;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.cat-list::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.category {
    display: flex;
    flex-flow: column;
    text-align: center;
    font-size: 10px !important;
    font-weight: bold;
    align-items: center;
}

.category img {
    width: 60px;
    height:60px;
    padding: 2px;
    border-radius: 50%;
}

.category p {
    color: rgb(53, 53, 67);
    font-style: normal;
    font-family: 'Mier Book';
    font-weight: 500;
    font-size: 13px;
    margin: 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 85px;
    overflow: hidden;
    text-align: center;
}
