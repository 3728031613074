.gNFCeh {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 16px 16px 18px;
    background-color: #FFFFFF;
}.hEBjyt {
    color: rgb(53, 53, 67);
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    font-family: "Mier Book";
    margin: 0px;
    padding: 0px;
}
.fill-grey-t2 {
    fill: rgb(139, 139, 163);
}.cHsEym {
    padding: 0px 16px 18px;
    background-color: #FFFFFF;
}.efQsfx {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: start;
    flex-direction: row;
    border-radius: 4px;
    background-color: rgb(231, 238, 255);
    padding: 6px 12px;
    gap: 10px;
}.eVDQPI {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding-left: 10px;
    height: 60px;
    width: 60px;
}.cOCnuI {
    display: flex;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    max-height: 50px;
    border-radius: 4px;
    background-color: rgb(231, 238, 255);
}.RrifI {
    color: rgb(85, 133, 248);
}
.eNkLGR {
    /* color: rgb(159, 32, 137); */
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    font-family: "Mier Book";
    margin: 0px;
    padding: 0px;
}.GmPbS {
    padding: 6px 16px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 10px;
    background-color: #FFFFFF;
}.accordion {
    margin: 0;
    padding: 0;
    list-style: none;
    /* border-top: 1px solid #e5e5e5; */
}
.accordion-item {
    border-top: unset !important;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px;
    position: relative;
}.accordion-thumb {
    margin: 0;
    padding: 5px 0;
    cursor: pointer;
    font-weight: normal;
    font-size: 16px;
}.plans .plan {
    cursor: pointer;
    width: 100%;
    margin-bottom: 5px;
}.plans .plan input[type="radio"] {
    position: absolute;
    opacity: 0;
}.plans .plan .plan-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 0 10px 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px solid #e1e2e7;
    /* border-radius: 6px; */
    -webkit-transition: -webkit-box-shadow 0.4s;
    transition: -webkit-box-shadow 0.4s;
    -o-transition: box-shadow 0.4s;
    transition: box-shadow 0.4s;
    transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
    position: relative;
}.plans .plan .plan-content .plan-details {
    width: 100%;
    padding-left: 35px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}.GmPbS span {
    font-weight: 600;
    font-size: 10px;
    font-family: "Mier book";
}.GmPbS div {
    height: 1px;
    background-color: rgb(206, 206, 222);
    -webkit-box-flex: 1;
    flex-grow: 1;
}.pay-logo {
    max-width: 30px;
    margin-right: 10px;
}
.plans .plan input[type="radio"]:checked+.plan-content:after {
    background: #216fe0;
    border: 3px solid #fff;
    -webkit-box-shadow: 0px 0px 0px 2px #0066ff;
    box-shadow: 0px 0px 0px 2px #0066ff;
}

.plans .plan input[type="radio"]+.plan-content:after {
    content: "";
    position: absolute;
    height: 15px;
    width: 15px;
    background: #ccc;
    left: 15px;
    top: 18px;
    border-radius: 100%;
    border: 7px solid #fff;
    -webkit-box-shadow: 0px 0px 0px 2px #ccc;
    box-shadow: 0px 0px 0px 2px #ccc;
}
.cart__footers {
    position: unset;
    box-shadow: unset;
    border-top: 5px solid #eaeaf2;
    border-bottom: 5px solid #eaeaf2;
    margin-bottom: 100px;
}
.cart__footers {
    /* position: absolute; */
    bottom: 0;
    background: white;
    width: 100%;
    padding: 10px;
    padding-bottom:65px;
    box-shadow: -1px 19px 19px 11px #ccc;
}.cart__total {
    /* -webkit-text-decoration: underline dotted; */
    text-decoration:none;
    color: rgb(53, 53, 67);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    font-family: "Mier Book";
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
}
