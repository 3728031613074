*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,body{

width: 100%;
height: 100%;
}

body a{
 
  text-decoration: none;
}
#root{
  max-width: 500px;
  margin: auto;
}
@font-face {
  font-family: 'Mier Demi';
  src: url('../public/Fonts/Mier_B02-Book.woff2') format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'Mier Book';
  src: url('../public/Fonts/Mier_B02-Demi.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}