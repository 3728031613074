.iyYCAll {
    display: none;
    -webkit-box-align: center;
    align-items: center;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    justify-content: space-evenly;
    z-index: 11;
    border-top: 1px solid rgb(234, 234, 242);
    box-shadow: rgba(34, 34, 34, 0.12) 0px 2px 16px 2px;
    padding: 5px 16px 4px;
    font-size: 12px;
    font-family: 'Mier Book';
    min-height: 48px;
}
a{
    text-decoration: none;
    color: #333;
}

.fctQgp {
    background-color: rgb(255, 255, 255);
    border-radius: 0px;
}.gREIBe {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}.jGNacQ {
    height: 26px;
    width: 26px;
    cursor: inherit;
}