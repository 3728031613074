.carousel-indicators .active {
    background-color: grey !important;
    width: 15px !important;
}
.carousel-indicators li {
    background-color: rgb(234, 234, 242) !important;
}
.carousel-indicators li {
    width: 10px !important;
    height: 5px !important;
    border: 0 !important;
    border-radius: 10px;
    list-style: none;
}
