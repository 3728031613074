.menu {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 10px 0;
    font-family: 'Mier Book';
    --marquee-width: 100vw;
    --offset: 20vw;
    --move-initial: calc(-25% + var(--offset));
    --move-final: calc(-50% + var(--offset));
    --item-font-size: 10vw;
    counter-reset: menu;
    background: #2874f0;
    display:none;
}

.marquee {
    width: var(--marquee-width);
    overflow: hidden;
    pointer-events: none;
}

.marquee .marquee__inner {
    animation-play-state: running;
    opacity: 1;
    transition-duration: 0.4s;
}

.marquee__inner {
    width: fit-content;
    display: flex;
    position: relative;
    transform: translate3d(var(--move-initial), 0, 0);
    animation: marquee 10s linear infinite; /* Adjusted timing */
    animation-play-state: running; /* Set to running for continuous animation */
    opacity: 1;
    transition: opacity 0.1s;
}

.marquee span {
    text-align: center;
    white-space: nowrap;
    font-size: 18px;
    padding: 0 1vw;
    font-weight: 900;
    line-height: 1.15;
    color: #ffffff;
}

@keyframes marquee {
    0% {
        transform: translate3d(var(--move-initial), 0, 0);
    }
    100% {
        transform: translate3d(var(--move-final), 0, 0);
    }
}

.eCQLMa {
    background-color: rgb(255, 255, 255);
    border-radius: 0px;
    padding-top: 10px;
    /* padding-left: 16px; */
    /* border-bottom: 10px solid #eaeaf2; */
}.fctQgp {
    background-color: rgb(255, 255, 255);
    border-radius: 0px;
}
.JgdIi {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.kZrHuA {
    color: rgb(97, 97, 115);
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 20px;
    font-family: "Mier book";
    margin: 0px 4px 0px 0px;
    padding: 0px;
}.eVLdcs {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    white-space: nowrap;
    font-variant-numeric: tabular-nums;
    background: rgb(255, 232, 205);
    border-radius: 4px;
    height: 20px;
    padding: 0px 8px;
    border: 0.8px solid rgb(255, 102, 55);
}.bZxmrh {
    color: rgb(159, 32, 137);
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    margin: 0px;
    padding: 0px;
}
