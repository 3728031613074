.check {
    display: flex;
    width: 100%;
    /* justify-content: space-between; */
    /* gap: 140px; */background-color: #2874f0;
    padding: 14px 20px;
}

.left {
    display: flex;
    width: 50%;
    align-items: center;
    gap: 19px;
}
.right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    gap: 17px;
}.navspan {
    margin-left: 0 !important;
    right: 0px !important;
    position: absolute !important;
    background: rgb(211, 47, 47) !important;
    border-radius: 50% !important;
    right: -8px !important;
    border: 2px solid #fff;
    top: -5px !important;
    font-size: 8px !important;
    font-family: 'Mier Book' !important;
    font-weight: bold !important;
    color: #fff !important;
    height: 15px !important;
    width: 15px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
