.sidenav {
    height: 100%;
    width: 90%;
    position: fixed;
    z-index: 999999;
    top: 0;
    right: -100%;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
}
.sidenav-div {
    padding: 10px;
}
.drawer__title {
    padding: 10px;
    font-size: 16px !important;
    display: flex;
    align-items: center;
    column-gap: 10px;
}.drawer__title h3.ui2-heading {
    font-size: 20px;
    margin: 5px;
}
h3.ui2-heading {
    color: #1A2024;
    font-size: 24px;
    text-align: left;
    margin-bottom: 20px;
    padding-left: 10px;
}h3.ui2-heading span {
    position: relative;
}h3.ui2-heading span::after {
    content: "";
    display: block;
    width: 24px;
    height: 2px;
    background: #ff9028;
    position: absolute;
    bottom: 0.5px;
    right: 0;
}.sidenav .closebtn {
    position: absolute;
    top: 12px;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    color: #000000;
}.cart-products-list {
    max-height: 60vh;
    overflow-y: scroll; /* Allows vertical scrolling */
    overflow-x: hidden; /* Hides horizontal scrolling if needed */
}

/* Hide scrollbar for webkit browsers (Chrome, Safari) */
.cart-products-list::-webkit-scrollbar {
    display: none; /* Hides the scrollbar */
}

/* Hide scrollbar for Firefox */
.cart-products-list {
    scrollbar-width: none; /* Hides the scrollbar */
}

/* Hide scrollbar for Edge and IE */
.cart-products-list {
    -ms-overflow-style: none; /* Hides the scrollbar */
}
.cart-product {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15px 0;
    border-bottom: 1px solid #eaeaea;
}.cart-product-img {
    width: 30%;
    background: #f7f9fa;
    border-radius: 10px;
}.cart-product-details {
    width: 60%;
}.cart-product-title {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 5px;
    margin-right: 5px;
}.cart-product-title p {
    font-size: 14px;
    font-weight: 900;
    text-align: left;
    text-overflow: ellipsis;
    font-family: 'Mier Book';
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    padding-right: 25px;
    margin: 0;
}.cart-product-title img.remove-cart-item {
    width: 16px;
}.cart-product-pricing {
    display: flex;
    align-items: baseline;
    margin-bottom: 12px;
}.cart-product-price {
    font-size: 13px;
    font-weight: 600;
    color: rgb(53, 53, 67);
    font-style: normal;
    font-weight: 400;
    font-family: "Mier Demi!";
    white-space: nowrap;
    margin: 0;
}.cart-product-mrp {
    text-decoration-line: line-through;
    color: #8b8b8b;
    font-size: 14px;
    font-family: 'Mier Book';
    margin-left: 10px;
}.cart-product-description {
    display: flex;
    align-items: center;
    justify-content: space-between;
}.cart-product-color {
    margin: 0;
    /* padding: 5px 10px; */
    /* background: #f7f9fa; */
    border-radius: 6px;
    font-weight: 600;
    color: rgb(53, 53, 67);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    font-family: "Mier Demi";
    white-space: nowrap;
    margin-right: 8px;
}.cart-qty-wrapper {
    height: 30px;
    width: 86px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    font-size: 16px;
    overflow: hidden;
    border: 1px solid #eff3f5;
    background: #eff3f5;
}.cart-qty-wrapper span {
    text-align: center;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    padding: 3px;
    overflow: hidden;
}.cart-qty-wrapper span.num {
    font-size: 14px;
    pointer-events: none;
    background: #FFF;
    padding: 3px 10px;
}

.cart-qty-wrapper span {
    text-align: center;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    padding: 3px;
    overflow: hidden;
}.cart-qty-wrapper span {
    text-align: center;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    padding: 3px;
    overflow: hidden;
}.cart__footer {
    position: absolute;
    bottom: 0;
    background: white;
    width: 100%;
    padding: 10px;
    box-shadow: -1px 19px 19px 11px #ccc;
}.cart__total, .shipping__total, .mc_pay__total {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    padding: 5px 0;
}.cart__total, .shipping__total, .mc_pay__total {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    padding: 5px 0;
}.cart__total, .shipping__total, .mc_pay__total {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    padding: 5px 0;
}.cart__checkout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
}.cart__final__payment {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 40%;
}.cart__final__price {
    font-weight: bold;
    font-family: 'Mier Book';
    font-size: 20px;
    margin: 0;
}.cart__tax__text {
    margin: 0;
    font-size: 12px;
    font-family: 'Mier Book';
}

.btn-dark {
    background-color: rgb(255, 194, 0)!important;
    color: #fff;
    font-family: 'Mier Book';
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid #fff; */
}
.cart-product-img img {
    width: 100%;

}

.cart__confirm__order {
    width: 55%;
    height: 50px;
    border-radius: 8px;
    font-weight: 400!important;
    font-size: 14px;
    /* font-family: 'Mier Book' !important; */
    color: black!important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border: none!important;
}

  .sidenav.open {
    right: 0; /* Sidebar slides into view */
  }
  
  /* Overlay styling */
  .overlay {
    position: fixed;
    z-index: 1000; /* Lower than the sidebar */
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5); /* Light black background */
    display: none;
  }
  
  .overlay.active {
    display: block; /* Overlay is shown when sidebar is open */
  }
  
  .btn:hover {
    color: #000!important;
    background-color: #424649!important;
    border-color: #373b3e!important;
}
