.detailscover{
  
        display: block;
        width: 100%;
        background: #f1f2f4;
        padding-bottom: 1px;
        /* margin-top: 15px; */
    
}.carousel-indicators {
    column-gap: 10px;
    /* background: #ffffff2b; */
    padding: 6px;
    border-radius: 29px;
    /* max-width: 100px; */
    margin: auto;
    margin-bottom: 20px;
    z-index: 0;
}
.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    /* z-index: 2; */
    display: flex;
    justify-content: center;
    /* padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%; */
}
.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

carousel-indicators .active {
    background-color: rgb(159, 32, 137) !important;
    width: 15px !important;
}

.carousel-indicators .active {
    opacity: 1;
}
.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    /* width: 30px;
    height: 3px; */
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    /* background-color: #fff; */
    background-clip: padding-box;
    /* border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5; */
    transition: opacity .6s ease;
}

.carousel-indicators li {
    /* width: 10px !important; */
    height: 5px !important;
    border: 0 !important;
    border-radius: 10px;
    list-style: none;
}
.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out;
}.product-slider img {
    height: 350px;
    width: auto !important;
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 50px;
}
.color-div h4 {
    text-align: left;
    color:#007185;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    font-family: "Mier Book" !important;
    margin: 0px;
    padding: 0px 0px 0px 16px;
}
.color-list {
    display: flex;
    overflow-x: scroll;
    align-items: baseline;
    width: 100%;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.color-list::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}
.color-div .color-item.active {
    border: 2.21348px solid #367dfb;
    ;
    border-radius: 10px;
}
.color-box {
    text-align: center;
    display: flex;
    flex-flow: column;
    align-items: center;
    border: 1px solid #ececec;
    border-radius: 6px;
}.color-div img {
    max-width: 60px;    
    /* max-height: 90px; */
    /* padding: 5px; */
    width: auto;
    height: unset;
}.color-div .color-name {
    font-size: 12px;
    text-align: center;
    font-weight: 700;
}.product-title-div {
    display: grid;
    grid-template-columns: auto 64px 64px;
}.product-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    color: #007185;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    font-family: "Mier Book";
    margin: 0px;
    padding: 0px;
}.gNlzBX {
    top: 10px;
    right: 8px;
    width: 64px;
    text-align: center;
}.bdcVUy {
    line-height: 20px;
    margin-bottom: 3px;
}.kwsBSS {
    height: 17px;
    width: 17px;
    cursor: pointer;
}
.iZRFqd {
    display: inline-block;
}

.gTFoAs {
    color: rgb(53, 53, 67);
    font-style: normal;
    font-weight: 500;
    font-size: small;
    line-height: 16px;
    font-family: "Mier Demi";
    margin: 0px;
    padding: 0px;
}
.cDBOnq {
    top: 10px;
    right: 8px;
    width: 64px;
    text-align: center;
}
.product-price {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.02px;
    line-height: 28px;
    margin: 5px 0px;
}
.product-price .price {
    color: rgb(53, 53, 67);
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 28px;
    font-family: "Mier Book";
    margin: 0px;
    padding: 0px;
}
.product-price .mrp {
    font-weight: 200;
    text-decoration-line: line-through;
    /* color: rgb(113, 116, 120); */
    margin: 0 10px;
    color: rgb(139, 139, 163);
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    font-family: "Mier Demi";
    text-decoration: line-through;
}
.product-price .discount {
    margin-right: 10px;
    color: rgb(53, 53, 67);
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    font-family: "Mier Demi";
}
.hdHcbs {
    margin-top: 10px;
    background-color: rgb(211, 244, 234);
    border-radius: 48px;
    color: rgb(3, 141, 99);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 2px 8px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    gap: 5px;
    width: max-content;
}
.aMaAEs ._3Zuayz {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: start;
}
.aMaAEs ._3_L3jD {
    cursor: pointer;
    display: inline-block;
}
.aMaAEs ._16VRIQ {
    display: flex;
    align-items: center;
}
.gUuXy-._1eJXd3 {
    margin-bottom: 6px;
    font-size: 16px;
}
.gUuXy- {
    margin-top: 6px;
}
._1lRcqv {
    position: relative;
}.aMaAEs ._3uSWvT {
    padding: 2px 7px;
    border-radius: 14px;
    font-size: 14px;
    background-color: #26a541;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: max-content;
}
._3LWZlK {
    line-height: normal;
    /* display: inline-block; */
    color: #fff;
    padding: 2px 4px 2px 6px;
    /* border-radius: 3px; */
    font-weight: 500;
    /* font-size: 12px; */
    /* vertical-align: middle; */
    background-color: #388e3c;
}.aMaAEs ._19cuvx {
    height: 12px;
}
._1wB99o {
    margin: 2px 0 0 2px;
    /* height: 10px; */
}.gUuXy- ._2_R_DZ {
    color: rgb(139, 139, 163);
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    font-family: "Mier book";
    margin-left: 10px;
    padding: 0px;
}.bxhscH {
    background: rgb(206, 206, 222);
    height: 4px;
    width: 4px;
    border-radius: 50%;
    margin: 0px 8px;
    display: inline-block;
}.aMaAEs ._2Z07dN {
    cursor: pointer;
}
.cYgzqn {
    padding-top: 3px;
}
.eeldyg.eeldyg {
/*     margin-bottom: 8px; */
}

.iGlGaV {
    background-color: rgb(255, 255, 255);
    border-radius: 0px;
    /* margin-bottom: 8px; */
    padding: 20px 16px;
    border-top: 5px solid #eaeaf2;
/*     border-bottom: 5px solid #eaeaf2; */
}.keaMtN.keaMtN {
    margin-bottom: 16px;
}
.hmRtgo {
    color: #007185;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    font-family: "Mier Book";
    margin: 0px;
    padding: 0px;
}
.hnbNOX {
    display: flex;
    flex-wrap: wrap;
}
.dress-size.active {
    background: #f1f4fa !important;
    border: 1px solid #2874f0 !important;
}
.dqLmGj {
    /* background: rgb(255, 255, 255); */
    padding: 6px 16px;
    border-radius: 40px;
    border: 1px solid rgb(53, 53, 67);
    margin: 6px 12px 6px 0px;
    min-width: 46px;
    height: 32px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}
.dress-size.active span {
    color: #2874f0;
}
.iwPwcX {
    color: rgb(53, 53, 67);
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    font-family: "Mier demi";
    text-align: center;
    margin: 0px;
    padding: 0px;
}
.button-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: -4px 3px 7px #bdbbbb;
    padding: 12px 16px;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 0px;
}
.buy_now {
    flex: 1 1 0%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    font-style: normal;
    text-align: center;
    letter-spacing: 0.0015em;
    font-size: 15px;
    line-height: 20px;
    border-radius: 4px;
 
    background: center center rgb(255, 194, 0);
    border: none;
    padding: 12px;
    transition: background 0.8s ease 0s;
    column-gap: 10px;
}
.buynow-button {
    position: relative;
    overflow: hidden;
}
.add_cart {
    flex: 1 1 0%;
    display: flex;
    margin-right: 10px;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    font-style: normal;
    text-align: center;
    /* font-family: 'Mier Book'; */
    letter-spacing: 0.0015em;
    font-size: 15px;
    font-weight:400;
    line-height: 20px;
    border-radius: 4px;
    color: black;
    background: center center rgb(255, 255, 255);
    border: 1px solid black;
    padding: 12px;
    transition: background 0.8s ease 0s;
    column-gap: 10px;
}
