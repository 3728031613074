.itivvW {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7); /* Darker overlay */
}

.loader-container {
  position: relative;
  width: 80px; /* Size of the loader */
  height: 80px; /* Size of the loader */
}

.loader {
  position: absolute;
  top: 27px;
  left: 50%;
  width: 45px; /* Size of the loader */
  height: 45px; /* Size of the loader */
  border-radius: 50%;
  border: 4px solid transparent; /* Transparent border */
  border-top: 4px solid #007BFF; /* Blue color */
  animation: spin 1.5s linear infinite; /* Spinning animation */
  transform: translate(-50%, -50%); /* Center the loader */
}

/* Keyframes for spinning animation */
@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
